export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0];

export const dictionary = {
		"/(marketing)": [23,[4]],
		"/(admin)/account": [14,[2]],
		"/(admin)/account/api": [15,[2]],
		"/(admin)/account/billing": [~16,[2]],
		"/(admin)/account/billing/manage": [~17,[2]],
		"/(admin)/account/cluster": [18,[2]],
		"/(admin)/account/profile": [19,[2,3]],
		"/(admin)/account/select_plan": [20,[2]],
		"/(admin)/account/sign_out": [21,[2]],
		"/(admin)/account/subscribe/[slug]": [~22,[2]],
		"/(marketing)/bentotest": [24,[4]],
		"/(marketing)/blog": [25,[4,5]],
		"/(marketing)/blog/(posts)/concourse_vs_travis": [26,[4,5,6]],
		"/(marketing)/blog/(posts)/why_managed_concourse": [27,[4,5,6]],
		"/(marketing)/contact_us": [28,[4]],
		"/(onboarding)/github/auth_app_generated": [32,[8]],
		"/(onboarding)/github/auth_app_setup/[synth_name]": [~33,[8]],
		"/(onboarding)/login": [~34,[8,9]],
		"/(onboarding)/login/authenticate": [35,[8,9,10]],
		"/(onboarding)/login/current_password_error": [36,[8,9]],
		"/(onboarding)/login/forgot_password": [37,[8,9]],
		"/(onboarding)/login/oauth": [38,[8,9]],
		"/(onboarding)/login/register": [39,[8,9,11]],
		"/(marketing)/mdtest": [29,[4,7]],
		"/(onboarding)/onboarding/configure_project": [40,[8,12]],
		"/(onboarding)/onboarding/create_profile": [41,[8,12,13]],
		"/(marketing)/pricing": [~30,[4]],
		"/(marketing)/privacy-policy": [31,[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';